



















import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { HugeSwitchOption } from './model';

@Component({
  name: 'HugeSwitch',
})
export default class HugeButtonSwitch extends Vue {
  private innerValue: string | boolean;

  @Prop({ required: true }) options: HugeSwitchOption[];
  @Prop() value: string | boolean;

  data() {
    return {
      innerValue: this.value ?? this.options[0].value,
    };
  }

  @Watch('innerValue')
  @Emit('change')
  onChange() {
    return this.innerValue;
  }

  selectOption(value: string | boolean) {
    this.innerValue = value;
  }
}
