





































import { Component, Vue, Ref } from 'vue-property-decorator';

import { FieldState } from './model';
import ROUTES from '@/router/ROUTES';
import GeneralModule from '@/store/modules/general/GeneralModule';
import SETTINGS_ROUTES from '@/modules/settings/router/SETTINGS_ROUTES';

import DropdownTitle from '@/components/ui/dropdown-title';
import Dropdown from '@/components/ui/dropdown';
import Input from '@/components/ui/input/Input.vue';
import Select from '@/components/ui/select/Select.vue';
import Button from '@/components/ui/button/Button.vue';
import HugeSwitch, { HugeSwitchOption } from '@/components/ui/huge-switch/';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import ContextMenuHeader from '@/components/context-menu/items/ContextMenuHeader.vue';
import ContextMenuToggle from '@/components/context-menu/items/ContextMenuToggle.vue';
import RolesModule from '@/store/modules/roles/RolesModule';


const emptyNewRole = {
  name: '',
  isAdmin: false,
  isMinor: false,
};

@Component({
  name: 'FirstSettings',
  components: {
    Input,
    Select,
    Button,
    DropdownTitle,
    Dropdown,
    HugeSwitch,
    ContextMenuSpawner,
    ContextMenuHeader,
    ContextMenuToggle,
  },
})
export default class FirstSettings extends Vue {
  $isDesktop: boolean;

  public rolesPath = ROUTES.SETTINGS + SETTINGS_ROUTES.ROLES_AND_RIGHTS;

  private newRole: any = Object.assign({}, emptyNewRole);

  routeMatched(route: string): boolean {
    return this.$route.matched.some(matchedRoute => matchedRoute.path === route);
  }

  public async createRole() {
    RolesModule.createRole(this.newRole);

    this.newRole = {
      ...this.newRole,
      name: '',
    };

    if (this.$isDesktop) {
      this.spawner.close();
    }
  }

  @Ref('addRoleMenu') readonly spawner!: ContextMenuSpawner;


  changeMinorState(value: boolean) {
    this.changeNewRoleFlag('isMinor', value);
  }

  changeAdminState(value: boolean) {
    this.changeNewRoleFlag('isAdmin', value);
  }

  changeNewRoleFlag(prop: 'isMinor' | 'isAdmin', state: boolean) {
    this.newRole[prop] = state;
  }
}
