







import { Vue, Component, Emit, Prop } from 'vue-property-decorator';

/**
 * @property {boolean} value
 * @emits change
 */
@Component({})
export default class ContextMenuToggle extends Vue {
  innerValue = false;

  @Prop({ default: false }) value: boolean;

  @Emit('change')
  toggle() {
    return (this.innerValue = !this.innerValue);
  }

  created() {
    this.innerValue = this.value;
  }
}
