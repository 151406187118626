enum SETTINGS_ROUTES {
  MAIN = '/',
  PAYMENT = '/payment',
  ROLES_AND_RIGHTS = '/roles-and-rights',
  DIRECTIONS = '/directions',
  KNOWLEDGE = '/knowledge',
  PROJECTS = '/projects',
  TEAMS = '/teams',
}

export default SETTINGS_ROUTES;
